.GifContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #ffffff;
    color: black;
    font-family: 'Times New Roman', Times, serif;
  }
  
  .gif {
    width: 35vw;
    height: 35vw;
  }

  .text{
    padding: 0;
    font-size: 5vw;
  }
