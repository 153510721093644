/* index.module.css */

.MainApp {
  background-color: #0E2423; 
  color: #FCFCFC;
  font-family: 'OCR A Std', 'Courier New', Courier, monospace;
  font-size: 15px;
  min-height: 100vh;
}

.MainApp.lightMode {
  background-color: #ffffff; 
  color: #000000; 
}

.MainApp.lightMode  a{
  color: #000000; 
}

.MainApp.lightMode  button{
  color: #000000; 
}

.MainApp  a, button {
  color: #ffffff; 
}


body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

