@import url('https://fonts.googleapis.com/css2?family=Jacquard+12&display=swap');


/* Pin */
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.PinContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.formContainer {
  padding: 0;
  margin: 0 auto;
  text-align: center;
}

.input[type="password"] {
  height: 40px;
  width: 150px;
  font-size: 3vh;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid black;
  background-color: black;
  color: white;
  display: block;
  margin: 0 auto;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.button {
  cursor: pointer;
  margin-top: 20px;
  width: 160px;
  font-size: 20px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid black;
  background-color: black;
  color: white;
}

.button {
  cursor: pointer;
  margin-top: 20px;
  width: 160px;
  font-size: 20px;
}


.pinbutton{
  cursor: pointer;
  margin-top: 20px;
  width: 160px;
  font-size: 20px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid black;
  background-color: #ffffff00;
  color: white;
}

/* Main */
.Heading {
  background-color: rgba(48, 54, 29, 0.25);
  padding: 100px;
}

.MainName {
  display: flex;
  justify-content: center;
}

.Intro {
  padding-top: 50px;
  display: flex;
  justify-content: center;
  margin: auto;
  text-align: center;
}

.IntroText {
  max-width: 700px;
  margin: auto;
}

/* Button Container */
.ButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.Button {
  background-color: #0E2423;
  color: white;
  border-radius: 8px;
  padding: 20px 25px;
  border: none;
  cursor: pointer;
  font-size: 20px;
  transition: background-color 0.3s;
}

.Button:hover {
  background-color: #1C4744;
}

/* Header */

.NavBar {
  background-color: rgba(255, 255, 255, 0);
  overflow: hidden;
  display: flex;
  justify-content: center; 
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  font-style: italic;
}

.NavContainer {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  width: 100%; 
  max-width: 1200px; 
  padding: 0 20px;
}

.NavList {
  list-style-type: none;
  margin: 0 auto; 
  padding: 0;
  display: flex;
}

.NavList li {
  margin-right: 10px;
}

.NavList li:last-child {
  margin-right: 0;
}

.NavList li a,
.NavList li span {
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  transition: color 0.3s;
}

.NavList li a:hover,
.NavList li span:hover {
  color: #416e41;
}

.NavList li a.active,
.NavList li span.active {
  color: #2a472a;
}

.navLink.lightMode {
  color: #000000;
}

.fa-moon {
  font-size: 24px; 
  color: #f2f2f2; 
}


/* Form */
.Form {
  background-color: rgba(168, 168, 168, 0.059);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.Form form {
  background-color: #0E2423;
  color: white;
  border-radius: 8px;
  padding: 50px;
  width: 450px;
  align-items: center;
}

.Form label {
  display: block;
  margin-bottom: 10px;
  padding-right: 10px;
}

.Form input[type="text"],
.Form input[type="email"],
.Form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
  max-height: 300px;
}

.Form textarea {
  resize: vertical;
}

.Form .ButtonContainer {
  display: flex;
  justify-content: space-between;
}

.Form button {
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
}

.SubmitButton {
  background-color: #0E2423;
  color: white;
  border: none;
}

.CancelButton {
  background-color: #ccc;
  color: #333;
  border: none;
}

.ButtonContainer button:hover {
  background-color: #1c403b;
}

/* Footer */
.Footer {
  background-color: rgba(25, 26, 25, 0);
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  font-style: italic;
}

.Footer.lightMode {
  color: #000000; 
}

.Footer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.Footer ul li {
  margin-right: 10px;
}

.Footer ul li:last-child {
  margin-right: 0;
}

.Footer ul li a {
  color: #f2f2f2;
  text-align: center;
  padding: 10px;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s;
}

.Footer.lightMode ul li a {
  color: #000000;
  text-align: center;
  padding: 10px;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s;
}

.Footer ul li a:hover {
  color: #416e41;
}

.Footer ul li a.active {
  color: #2a472a;
}

.skillsList {
  list-style-type: none;
}

.H1 {
  font-family: 'Jacquard 12', Times, 'Times New Roman', Georgia, serif; 
  font-size: 100px;
  font-weight: lighter;
}


/* Responsive Styles */
@media screen and (max-width: 768px) {

  .H1{
    font-size: 23vw;
  }
  .container {
    padding: 20px;
    margin-bottom: 20px;
  }

  .Intro {
    padding-top: 20px;
    font-size: 3vw;
  }

  .IntroText {
    width: 100%;
    max-width: 600px;
  }

  .Footer ul li a {
    font-size: 3vw;
  }

  .Heading {
    background-color: rgba(48, 54, 29, 0.25);
    padding: 70px;
  }

  .Button {
    font-size: 3vw;
    padding: 5vw;
  }

  .NavBar {
    padding-top: 5vw;
    padding-bottom: 5vw;
  }
  .NavBar ul li a,
  .NavBar ul li span  {
    font-size: 3vw;
  }

  
}

